import React, { useContext, Fragment, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AccountStoreContext } from '../store/AccountStore.js';
import styles from './../CardSection/card.module.css';
import { getFirestore, collection, query, getDocs, orderBy } from "firebase/firestore";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const PostsTab = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const [posts, setPosts] = useState([]);
  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  
  const handleOpenDialog = (index) => {
    setOpenDialogIndex(index);
  };

  const handleCloseDialog = () => {
    setOpenDialogIndex(null);
  };

  const fetchAllPostsByAccount = useCallback(async (selectedAccountData) => {
    const db = getFirestore();
    const q = query(collection(db, "posts"), orderBy("postDate", 'asc'));
    const querySnapshot = await getDocs(q);

    let posts = [];
    querySnapshot.forEach((doc) => {
      posts.push(doc.data());
    });
    setPosts(posts);
  }, []);

  const formatText = (text) => {
    const regex = /<br>(.*?)<\/br>/g;
    const newText = text.replace(regex, '<strong>$1</strong>');
    return { __html: newText };
  };

  useEffect(() => {
    if(accountStore.posts.length === 0){
      fetchAllPostsByAccount(accountStore.selectedAccountData);
    } else {
      setPosts(accountStore.posts);
    }
  }, [accountStore.posts, accountStore.selectedAccountData, fetchAllPostsByAccount, setPosts]);

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20 }}>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', margin: 8, display: 'flex', marginLeft: 10, marginTop: 10, position: 'relative' }}>
          Posts
        </p>
      </div>
      <div>
        <table className="posts-table">
          <tbody>
            {accountStore.accounts.map((account) => (
                <Fragment key={account.accountName}>
                    <tr>
                        <td colSpan="4" style={{ gap: 16, alignItems: 'center', fontWeight: 'bold', padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '10px', width: '100%' }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                            <img src={account.profileImage} alt={`${account.accountName} profile`} style={{ height: '30px', width: '30px', borderRadius: '50%', objectFit: 'cover' }} />
                            <p style={{ fontSize: 18, margin: 0 }}>{account.accountName}</p>
                          </div>
                        </td>
                    </tr>
                    {posts
                        .filter((post) => post.accountName === account.accountName)
                        .map((post) => {
                          const uniqueKey = `${account.accountName}-${post.postTitle}`;
                          return(
                            <Fragment key={uniqueKey}>
                          <tr key={uniqueKey} onClick={() => handleOpenDialog(uniqueKey)} style={{ cursor: 'pointer' }}>
                            <td>
                              <div style={{ width: 10, height: 10, border: '1px solid #a0a096', borderRadius: '50%', backgroundColor: accountStore.isAdmin ? post.postDate._seconds * 1000 < new Date().getTime() ? '#a0a096' : '#4C6FFF' : post.postDate.seconds * 1000 < new Date().getTime() ? '#a0a096' : '#4C6FFF' }} />
                            </td>
                            <td style={{ padding: '10px 20px', width: '10%' }}>
                              <p style={{ fontSize: 12, margin: 0 }}>
                                {new Date(accountStore.isAdmin ? post.postDate._seconds * 1000 : post.postDate.seconds * 1000).toLocaleDateString()}
                              </p>
                            </td>
                            <td style={{ width: '10%' }}>
                              <img src={post.image} alt="" style={{ height: 120, borderRadius: '5%', objectFit: 'cover' }} />
                            </td>
                            <td style={{ padding: '10px 20px', width: '80%' }}>
                              <p className={styles.cardTitle} style={{ fontSize: 14, margin: 0 }}>{post.postTitle}</p>
                            </td>
                          </tr>
                          <Dialog
                            onClose={handleCloseDialog}
                            open={openDialogIndex === uniqueKey}
                          >
                              <DialogTitle id="customized-dialog-title">
                                {accountStore.isAdmin ? new Date(post.postDate._seconds * 1000).toLocaleDateString() : new Date(post.postDate.seconds * 1000).toLocaleDateString()}
                              </DialogTitle>
                              <IconButton
                                aria-label="close"
                                onClick={handleCloseDialog}
                                sx={(theme) => ({
                                  position: 'absolute',
                                  right: 8,
                                  top: 8,
                                  color: theme.palette.grey[500],
                                })}
                              >
                                <CloseIcon />
                              </IconButton>
                              <DialogContent dividers>
                                <div className={styles.card}>
                                  <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <img src={post.image} alt="card" className="card-image" style={{ padding: 10, borderRadius: 20, height: 200, width: 200, cursor: 'pointer' }} />
                                  </div>
                                  <div className={styles.cardContent} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div className='article' style={{ display: 'flex', flexDirection: 'column', gap: 12, padding: 10 }}>
                                      <h2 className={styles.cardTitle} style={{ fontSize: 24, width: '95%', margin: 0 }}>{post.postTitle}</h2>
                                      <p className={styles.cardText} style={{ fontSize: 18, width: '95%', margin: 0 }} dangerouslySetInnerHTML={formatText(post.postText)}></p>
                                    </div>
                                    <div className='elements' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10, marginBottom: 4 }}>
                                      <div className={styles.cardHashtags}>{post.hashtags && post.hashtags.length > 0 && post.hashtags.map(hashtag => <span key={`${hashtag}-${uniqueKey}`} style={{ position: 'relative', background: '#F4F8F9', padding: 10, borderRadius: 20, margin: 5, fontWeight: 'bold', height: 10, fontSize: 12, display: 'inline-block', flexWrap: 'wrap' }}>{hashtag}</span>)}</div>
                                    </div>
                                  </div>
                                </div>
                              </DialogContent>
                            </Dialog>
                            </Fragment>
                          );
                  })}
                </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
})
export default PostsTab;