import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AccountStoreContext } from '../store/AccountStore.js';

const UsersView = observer(() => {
  const accountStore = useContext(AccountStoreContext);

  const groupedAccounts = accountStore.accounts.reduce((acc, account) => {
    if (!acc[account.displayName]) {
      acc[account.displayName] = [];
    }

    acc[account.displayName].push(account.accountName);

    return acc;
  }, {});

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20 }}>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', margin: 8, display: 'flex', marginLeft: 10, marginTop: 10, position: 'relative' }}>
          Users
        </p>
      </div>
      <div>
        <table className="accounts-table">
          <tbody>
            {Object.keys(groupedAccounts).map((displayName) => (
              <React.Fragment key={displayName}>
                <tr>
                  <td colSpan="2" style={{ fontWeight: 'bold', padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '10px' }}>
                    {displayName}
                  </td>
                </tr>
                {accountStore.accounts
                  .filter((account) => account.displayName === displayName)
                  .map((account, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: 'center', width: 40 }}>
                        <img src={account.profileImage} alt={`${account.accountName} profile`} style={{ height: '30px', width: '30px', borderRadius: '50%', objectFit: 'cover' }} />
                      </td>
                      <td style={{ padding: '10px 20px' }}>
                        {account.accountName}
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
})
export default UsersView;