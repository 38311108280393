import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import { AccountStoreContext } from './store/AccountStore';
import LoginBox from './LoginBox/LoginBox';
import DashboardDesktop from './Dashboard/DashboardDesktop';
import DashboardMobile from './Dashboard/DashboardMobile';
import { IsMobileProvider } from './Tools/IsMobileContext';
import { IsMobileContext } from './Tools/IsMobileContext';
import './App.css';

const App = () => {

  const isMobile = useContext(IsMobileContext);

  console.log('isMobile in App:', isMobile);

  const DashboardWithContext = () => {
    const isMobile = useContext(IsMobileContext);

    return isMobile ? <DashboardMobile /> : <DashboardDesktop />;
  };

  return (
    <IsMobileProvider>
      <AccountStoreContext.Provider value={AccountStoreContext._currentValue}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<LoginBox />} />
              <Route path="/dashboard" element={<DashboardWithContext />} />
            </Routes>
          </Router>
        </AuthProvider>
      </AccountStoreContext.Provider>
    </IsMobileProvider>
  );
}
export default App;