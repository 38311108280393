import React, { useContext, useEffect, useState, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { observer } from 'mobx-react';
import { AccountStoreContext } from '../store/AccountStore.js';
import { format, eachDayOfInterval, addMonths, addDays, differenceInCalendarDays } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

let newDate;
let highlightedDates = [];

const CalendarComponent = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const frequency = accountStore.daily;
  let initialTimestamp = accountStore.startDate ? accountStore.startDate : Timestamp.now();
  const [date, setDate] = useState(initialTimestamp);
  const [postMessage, setPostMessage] = useState("");
  

  useEffect(() => {
    if (accountStore.startDate) {
      if (!newDate || newDate.seconds !== accountStore.startDate.seconds) {
        newDate = accountStore.startDate;
        setDate(accountStore.startDate);
      }
    }
  }, [accountStore.startDate]);

  const updateDates = useCallback((startTimestamp) => {
    const currentDate = startTimestamp.toDate();
    let endDate = addMonths(currentDate, 1);
    let allDays;
    let dates = [];

    try {
      allDays = eachDayOfInterval({ start: currentDate, end: endDate });
    } catch (e) {
      console.error("Error in eachDayOfInterval:", e);
      return;
    }
    let nextDate = currentDate;
    switch (frequency) {
      case 'daily':
        break;
      case 'weekly':
        
        while (nextDate <= allDays[allDays.length - 1]) {
          dates.push(format(nextDate, 'MM/dd/yyyy'));
          highlightedDates.push(nextDate);
          nextDate = addDays(nextDate, 7);
        }
        break;
      case 'biweekly':
        while (nextDate <= allDays[allDays.length - 1]) {
          dates.push(format(nextDate, 'MM/dd/yyyy'));
          highlightedDates.push(nextDate);
          nextDate = addDays(nextDate, 14);
        }
        break;
      default:
        break;
    }
    if (frequency === 'daily') {
      setPostMessage("Posts will be made every day of this month.");
    } else {
      setPostMessage(`Posts will be made on the following dates: ${dates.join(', ')}`);
    }
  }, [frequency]);

  useEffect(() => {
    highlightedDates=[];
    updateDates(date);
  }, [date, frequency, updateDates]);

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const timestamp = Timestamp.fromDate(date);
      setDate(timestamp);
      if (!accountStore.startDate || accountStore.startDate.seconds !== timestamp.seconds) {
        accountStore.setStartDate(timestamp);
      }
    } else {
      console.error("Invalid date selected:", date);
      setDate(Timestamp.now());
    }
  };

  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }
  function tileClassName({ date, view }) {
    if (
      view === 'month' &&
      highlightedDates.find((dDate) => isSameDay(dDate, date))
    ) {
      return 'highlight';
    }
  }

  return (
    <div className="App" style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
    <div style={{display: 'flex', justifyContent: 'center'}}>
    <Calendar onChange={handleDateChange} value={date.toDate()} onActiveStartDateChange={({ activeStartDate }) => updateDates(Timestamp.fromDate(accountStore.startDate.toDate()))} tileClassName={tileClassName}/>
    </div>
      
      <p style={{ fontSize: 12 }}>
        {postMessage}
      </p>
    </div>
  );
});
export default CalendarComponent;