import { observer } from 'mobx-react';
import React, { useContext, useState, useEffect } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';
import { getFirestore, doc, addDoc, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { AuthContext } from '../AuthContext';
import { getAuth } from 'firebase/auth';
import ProfileForm from '../ProfileForm/ProfileForm.js';
import Signature from '../Signature/Signature.js';
import SocialMediaConnection from '../SocialMediaConnection/SocialMediaConnection.js';
import Payment from '../Payment/Payment.js';

const ProfileSection = observer(() => {
  const user = useContext(AuthContext);
  const accountStore = useContext(AccountStoreContext);
  const [, setErrorMessage] = useState("");
  const [emailVerified] = useState("");
  const [email] = useState("");

  useEffect(() => {
    const fetchAccounts = async () => {
      const db = getFirestore();
      const q = query(collection(db, "accounts"), where("displayName", "==", user?.displayName));
      const querySnapshot = await getDocs(q);
      let accounts = [];
      querySnapshot.forEach((doc) => {
        accounts.push(doc.data());
      });
      accountStore.setAccounts(accounts);
    };
    if (user) {
      fetchAccounts();
    }
  }, [accountStore, user]);

  const saveAccount = async () => {
    if (accountStore.ayrKey === "" || accountStore.openAIKey === "" || accountStore.accountName === "" || accountStore.displayName === "" || accountStore.email === "") {
      setErrorMessage('missing fields');
      return;
    }

    if (accountStore.facebook === false && accountStore.instagram === false && accountStore.twitter === false && accountStore.linkedIn === false && accountStore.blog === false) {
      setErrorMessage('you need connect at least one social platform');
      return;
    }

    const db = getFirestore();
    console.log('Saved Img:', accountStore.profileImage);
    const accountData = {
      displayName: user.displayName,
      email: accountStore.email,
      website: accountStore.website,
      phone: accountStore.phone,
      emailVerified: emailVerified,
      profileImage: accountStore.profileImage,
      accountName: accountStore.accountName,
      ayrKey: accountStore.ayrKey,
      openAIKey: accountStore.openAIKey,
      facebook: accountStore.facebook,
      instagram: accountStore.instagram,
      twitter: accountStore.twitter,
      linkedIn: accountStore.linkedIn,
      blog: accountStore.blog,
      signatures: accountStore.signatures,
      payment: accountStore.payment
    };

    if ((accountStore.selectedAccountData instanceof Object)) {
      if (!accountStore || !accountStore.accountName) {
        console.error("accountStore or accountStore.accountName is undefined");
        return;
      }

      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();
      const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
      const querySnapshot = await getDocs(q);
      let accounts = [];
      querySnapshot.forEach((doc) => {
        const account = doc.data();
        console.log("Test1", doc.data);
        accounts.push(account);
      });

      if (user) {
        const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0];
          const accountDoc = doc(db, "accounts", docData.id);
          console.log("Updating document:", accountDoc);
          console.log("accountStore.keyword1", accountStore.keyword1);

          try {
            await updateDoc(accountDoc, {
              displayName: user.displayName,
              email: accountStore.email,
              emailVerified: emailVerified,
              profileImage: accountStore.profileImage,
              accountName: accountStore.accountName,
              ayrKey: accountStore.ayrKey,
              openAIKey: accountStore.openAIKey,
              facebook: accountStore.facebook,
              instagram: accountStore.instagram,
              twitter: accountStore.twitter,
              linkedIn: accountStore.linkedIn,
              blog: accountStore.blog,
              website: accountStore.website,
              phone: accountStore.phone,
              signatures: accountStore.signatures !== undefined ? accountStore.signatures : [],
              payment: accountStore.payment
            });
            console.log("Update successful", {
              displayName: user.displayName,
              email: email,
              emailVerified: emailVerified,
              profileImage: accountStore.profileImage,
              accountName: accountStore.accountName,
              ayrKey: accountStore.ayrKey,
              openAIKey: accountStore.openAIKey,
              facebook: accountStore.facebook,
              instagram: accountStore.instagram,
              twitter: accountStore.twitter,
              linkedIn: accountStore.linkedIn,
              blog: accountStore.blog,
              website: accountStore.website,
              phone: accountStore.phone,
              signatures: accountStore.signatures
            });
          } catch (error) {
            console.error("Error updating document:", error);
          }
        } else {
          console.error("No document found with accountName:", user.accountName);
        }
      }
    } else {
      const docRef = await addDoc(collection(db, "accounts"), accountData);
      const newAccount = {
        ...accountData,
        id: docRef.id
      };
      accountStore.addAccount(newAccount);
    }
    window.location.reload();
  };

  return (
    <div style={{ width: '100%', marginLeft: 50, marginRight: 50, height: '100%' }}>
      <div className="container" style={{ padding: 20, borderRadius: 20, marginLeft: 'auto', marginRight: 'auto', width: 'calc(100% - 80px)', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, padding: 10 }}>
          <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', marginBottom: 10, marginTop: 10 }}>
              Profile
            </p>
            <ProfileForm />
          </div>
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', marginBottom: 10, marginTop: 10 }}>
              Social Connections
            </p>
            <SocialMediaConnection />
          </div>
        </div>
        <div style={{ flex: 1, padding: 10 }}>
          {/* <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', marginBottom: 10, marginTop: 10 }}>
              Payment Info
            </p>
            <Payment />
          </div> */}
          <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', marginBottom: 10, marginTop: 10 }}>
              Signature
            </p>
            <Signature />
          </div>
        </div>
      </div>
      <div className="fixed-buttons" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', rowGap: 8, paddingBottom: 20 }}>
          <div onClick={saveAccount} style={{ fontSize: 12, height: 40, borderRadius: 10, width: '100%', maxWidth: 350, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', cursor: 'pointer', color: 'white' }} className="button">
            Save
          </div>
          <div style={{ fontSize: 12, height: 40, borderRadius: 10, width: '100%', maxWidth: 350, margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', cursor: 'pointer', color: 'white' }}
            className="button"
            onClick={() => {
              accountStore.setShowProfile(false);
            }}>
            Cancel
          </div>
        </div>
    </div>
  );
})
export default ProfileSection;