import React, { useContext, useEffect, useState } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';
import { observer } from 'mobx-react-lite';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Header from '../Header/HeaderDesktop.js';
import LeftSection from '../LeftSection/LeftSection.js';
import PreviewSection from '../PreviewSection/PreviewSection.js';
import RightSection from '../RightSection/RightSection.js';
import ProfileSection from '../ProfileSection/ProfileSection.js';
import ManagementView from '../ManagementView/ManagementView.js';
import { checkAdminAndFetch } from './DashboardLogic.js'

const DashboardDesktop = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const [managementSelected, setManagement] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkAdminAndFetch(accountStore);
      } else {
        console.error("User is not authenticated.");
      }
    });
    return () => unsubscribe();
  }, [accountStore]);

  const handleButtonClick = () => {
    setManagement(!managementSelected);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        `}
      </style>
      <div style={{ display: 'flex', width: '100%', height: 56, background: '#FFFFFF', alignItems: 'center' }}>
        <Header isAdmin={accountStore.isAdmin} onButtonClick={handleButtonClick} />
      </div>
      {!accountStore.showProfile ? (
        <div style={{ width: '100%', height: 'calc(100vh - 56px)' }}>
          {managementSelected ?
            <div style={{ display: 'flex', justifyContent: 'center', height: '100%', overflowX: 'hidden' }}>
              <ManagementView isAdmin={accountStore.isAdmin} />
            </div> :
            <div style={{ display: 'flex', height: '100%', overflowX: 'hidden', width: '100%' }}>
              <div id="left-section" style={{ width: '20%', height: '100%', background: '#FFFFFF', overflowY: 'auto' }}>
                <LeftSection />
              </div>
              <div style={{ width: '60%', height: '100%', overflowY: 'auto' }}>
                <PreviewSection />
              </div>
              <div id="right-menu-desktop-wrap" style={{ width: '20%', height: '100%', background: '#FFFFFF', overflowY: 'auto'}}>
                <RightSection />
              </div>
            </div>}
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 56px)', overflowX: 'hidden' }}>
          <ProfileSection />
        </div>
      )}
    </div>
  );
})
export default DashboardDesktop;