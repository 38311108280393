import { observer } from 'mobx-react-lite';
import React, { useState, useContext } from "react";
import { AccountStoreContext } from '../store/AccountStore';

const InventoryPosting = observer(() => {
  const [itemTitle, setItemTitle] = useState("");
  const [itemKeyword, setItemKeyword] = useState("");
  const accountStore = useContext(AccountStoreContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newItem = { itemTitle, itemKeyword };
    console.log("New Item", newItem);
    const updatedList = [...accountStore.inventory, newItem];
    accountStore.setInventory(updatedList);
    setItemTitle("");
    setItemKeyword("");
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', margin: 'auto', background: 'white', alignItems: 'center', paddingTop: 8 }}>
        <form onSubmit={handleSubmit} style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '100%'}}>
          <input type="text" placeholder="Insert Item Title" value={itemTitle} onChange={(e) => setItemTitle(e.target.value)} style={{ background: 'rgb(245, 245, 245)', borderRadius: 8, border: '0px solid', height: 30, margin: '5px 7px', width:'75%', paddingLeft: 10 }} />
          <input type="text" placeholder="Insert Keyword" value={itemKeyword} onChange={(e) => setItemKeyword(e.target.value)} style={{ background: 'rgb(245, 245, 245)', borderRadius: 8, border: '0px solid', height: 30, margin: '5px 7px', width:'75%', paddingLeft: 10 }} />
          <button style={{ height: 30, width: 100, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, borderRadius: 10, marginLeft: 10, marginTop: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontSize: 14, border: 'none' }} type="submit" className="button">
            Add
          </button>
        </form>
        <ul style={{ height: 100, overflowY: 'scroll', listStyle: 'none', background: 'rgb(238 238 238)', borderRadius: 10, width: '100%', maxWidth: 200, paddingLeft: 10, paddingTop: 10, position: 'relative', left: 10 }}>
          {accountStore.inventory.map((item, index) => (
            <li key={index}>
              <strong>{item.itemTitle}</strong> - <strong>{item.itemKeyword}</strong>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
})
export default InventoryPosting;