import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';

const Signature = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const [tags, setTags] = useState(accountStore.signatures || []);
  const [input, setInput] = useState("");

  const addTag = (e) => {
    e.preventDefault();
    if (input.trim() !== "" && !tags.includes(input)) {
      const updatedTags = [...tags, input];
      setTags(updatedTags);
      setInput("");
      accountStore.setSignatures(updatedTags);
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
    accountStore.setSignatures(tags);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '75%', background: 'white', position: 'relative', marginTop: 20, borderRadius: 15, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 32px', marginBottom: 20, marginLeft: 'auto', marginRight: 'auto', padding: 16, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 10 }}>
          {tags.map((tag, index) => (
            <div key={index} style={{ backgroundColor: 'black', color: 'white', display: 'flex', alignItems: 'center', borderRadius: 25, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, fontSize: 16 }}>
              <span>{tag}</span>
              <span style={{ marginLeft: 8, cursor: 'pointer', fontSize: 18, color: 'white' }} onClick={() => removeTag(index)}>
                &times;
              </span>
            </div>
          ))}
          <form style={{ display: 'flex', alignItems: 'center', gap: 10 }} onSubmit={addTag}>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              style={{ border: 'none', outline: 'none', padding: 10, fontSize: 16, borderRadius: 25, backgroundColor: '#f0f0f0', width: 150}}
              placeholder="Add a tag"
            />
            <button type="submit" style={{ backgroundColor: 'rgb(76, 111, 255)', border: 'none', color: 'white', borderRadius: 50, fontSize: 24, width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
              +
            </button>
          </form>
        </div>
      </div>
    </div>
  );
})
export default Signature;