import { observer } from 'mobx-react';
import { useEffect, useContext, useState, useCallback } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from 'firebase/firestore';
import './UpcomingPostsDropdown.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './../CardSection/card.module.css';
import Alert from '@mui/material/Alert';

const UpcomingPostsDropdown = observer(() => {
    const accountStore = useContext(AccountStoreContext);
    const [openDialogIndex, setOpenDialogIndex] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpenDialog = (index) => {
        setOpenDialogIndex(index);
    };
    const handleCloseDialog = () => {
        setOpenDialogIndex(null);
    };

    const fetchUpcomingPosts = useCallback(async (selectedAccountData) => {
        if(selectedAccountData === null){
            setOpen(true);
        } else {
            const db = getFirestore();
            const q = query(collection(db, "posts"), where("accountName", "==", selectedAccountData?.accountName), where("postDate", ">=", Timestamp.fromDate(new Date())));
            const querySnapshot = await getDocs(q);
        
            let posts = [];
            querySnapshot.forEach((doc) => {
            posts.push(doc.data());
            });
            accountStore.setUpcomingPosts(posts);
        }
    }, [accountStore]);

    const handleClose = () => {
        setOpen(false);
      };
  
    useEffect(() => {
        fetchUpcomingPosts(accountStore.selectedAccountData);
    }, [accountStore.selectedAccountData, fetchUpcomingPosts]);

    const formatText = (text) => {
        const regex = /<br>(.*?)<\/br>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText };
    };

    return (
        <>
        <ul style={{ padding: 8, margin: 0 }}>
            {accountStore.upcomingPosts.length === 0 ? 
                <Alert severity="error">There is None Upcoming Posts</Alert> : 
                accountStore.upcomingPosts.map((data, index) => {
                    return(
                        <li key={index} style = {{ listStyle: 'none' }}>
                            <div className='upcoming-post-hover' style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ccc' }}>
                                <div onClick={() => handleOpenDialog(index)} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={data.image} alt="card" className="card-image" style={{ padding: 10, borderRadius: 20, height: 56, width: 56 }} />
                                    <p style={{ fontSize: 12, fontFamily: 'nexa_lightregular', fontWeight: 'bold' }}>
                                        {data.postTitle}
                                    </p>
                                </div>
                                <p style={{ fontSize: 12, fontFamily: 'nexa_lightregular', fontWeight: 'bold', margin: 8 }}>
                                    {new Date(data.postDate.seconds * 1000).toLocaleDateString()}
                                </p>
                            </div>
                            <Dialog
                                onClose={handleCloseDialog}
                                open={openDialogIndex === index}
                            >
                                <DialogTitle id="customized-dialog-title">
                                    {new Date(data.postDate.seconds * 1000).toLocaleDateString()}
                                    </DialogTitle>
                                    <IconButton
                                    aria-label="close"
                                    onClick={handleCloseDialog}
                                    sx={(theme) => ({
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: theme.palette.grey[500],
                                    })}
                                    >
                                    <CloseIcon />
                                    </IconButton>
                                    <DialogContent dividers>
                                        <div className={styles.card}>
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                <img src={data.image} alt="card" className="card-image" style={{ padding: 10, borderRadius: 20, height: 200, width: 200, cursor: 'pointer' }} />
                                            </div>
                                            <div className={styles.cardContent} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <div className='article' style={{ display: 'flex', flexDirection: 'column', gap: 12, padding: 10 }}>
                                                    <h2 className={styles.cardTitle} style={{ fontSize: 24, width: '95%', margin: 0 }}>{data.postTitle}</h2>
                                                    <p className={styles.cardText} style={{ fontSize: 18, width: '95%', margin: 0 }} dangerouslySetInnerHTML={formatText(data.postText)}></p>
                                                </div>
                                                <div className='elements' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10, marginBottom: 4 }}>
                                                    <div className={styles.cardHashtags}>{data.hashtags && data.hashtags.length > 0 && data.hashtags.map(hashtag => <span key={`${hashtag}-${index}`} style={{ position: 'relative', background: '#F4F8F9', padding: 10, borderRadius: 20, margin: 5, fontWeight: 'bold', height: 10, fontSize: 12, display: 'inline-block', flexWrap: 'wrap' }}>{hashtag}</span>)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContent>
                            </Dialog>
                        </li>
                    );
                })
            }
        </ul>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Error"}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Alert severity="error">Please Select an Account</Alert>
            </DialogContent>
        </Dialog>
      </>
    );
})
export default UpcomingPostsDropdown;