import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { getAuth } from 'firebase/auth';
import KeywordPosting from '../KeywordPosting/KeywordPosting';
import InventoryPosting from '../InventoryPosting/InventoryPosting';

const TabMenu = observer(() => {
  const [activeTab, setActiveTab] = useState('Keywords');
  const auth = getAuth();
  const user = auth.currentUser;
  
  useEffect(() => {
    if (user) {
      console.log("Logged in user:", user);
    }
  }, [user]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Keywords':
        return (
          <KeywordPosting />
        );

      case 'Inventory':
        return (
          <div>
            <InventoryPosting />
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex', height: 30, boxShadow: 'rgba(0, 0, 0, 0.1) 0px -1px 0px inset' }}>
        <span
          style={{ cursor: 'pointer', fontWeight: 'bold', marginLeft: 0, fontSize: 11, paddingBottom: 11, borderBottom: activeTab === 'Keywords' ? '2px solid blue' : '0px', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'nexa_boldregular' }}
          onClick={() => setActiveTab('Keywords')}>
          Keywords
        </span>
        <span
          style={{ cursor: 'pointer', fontWeight: 'bold', marginLeft: 0, fontSize: 11, paddingBottom: 11, borderBottom: activeTab === 'Inventory' ? '2px solid blue' : '0px', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'nexa_boldregular' }}
          onClick={() => setActiveTab('Inventory')}>
          Inventory
        </span>
      </div>
      {renderTabContent()}
    </div>
  );
})
export default TabMenu;