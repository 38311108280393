import React, { useState } from 'react';

import PreviewSection from '../PreviewSection/PreviewSection';
import RightSection from '../RightSection/RightSection';
import LeftSection from '../LeftSection/LeftSection';


function TabMenuMobile() {
  const [activeTab, setActiveTab] = useState('Prep');
  const tabStyle = {
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white'
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: '2px solid blue',
    backgroundColor: 'rgb(242, 241, 239)'
  };


  const renderTabContent = () => {
    switch (activeTab) {
      case 'Prep':
        return (
          <LeftSection />
        );
      case 'Preview':
        return (
          <PreviewSection />
        );
      case 'Post':
        return (
          <RightSection />
        );

      default:
        return null;
    }
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
      <div style={{ height: 30, width: '100%', margin: 'auto', display: 'flex', position: 'fixed', zIndex: 99, top: 70 }}>
        <div style={activeTab === 'Prep' ? activeTabStyle : tabStyle} onClick={() => setActiveTab('Prep')}>
          Prep
        </div>
        <div style={activeTab === 'Preview' ? activeTabStyle : tabStyle} onClick={() => setActiveTab('Preview')}>
          Preview
        </div>
        <div style={activeTab === 'Post' ? activeTabStyle : tabStyle} onClick={() => setActiveTab('Post')}>
          Post
        </div>
      </div>
      <div style={{ marginTop:50 ,display: 'flex', justifyContent: 'center' }}>
        {renderTabContent()}
      </div>
    </div>
  );
}

export default TabMenuMobile;
