import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { jwtDecode } from "jwt-decode";

/**
 * Fetch all accounts if the user is an admin.
 */
export const fetchAllAccounts = async (accountStore) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error("User is not authenticated.");
    return;
  }

  const functions = getFunctions();
  const getAllAccounts = httpsCallable(functions, 'getAllAccounts');

  try {
    const result = await getAllAccounts();
    accountStore.setAccounts(result.data.accounts);
  } catch (error) {
    console.error("Error fetching accounts:", error);
  }
};

const fetchAllPosts = async (accountStore) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error("User is not authenticated.");
    return;
  }

  const functions = getFunctions();
  const getAllPosts = httpsCallable(functions, 'getAllPosts');

  try {
    const result = await getAllPosts();
    accountStore.setPosts(result.data.posts);
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
};

/**
 * Fetch accounts for a specific user based on their displayName.
 */
export const fetchAccounts = async (accountStore) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error("User is not authenticated.");
    return;
  }

  const db = getFirestore();
  const q = query(collection(db, "accounts"), where("displayName", "==", user?.displayName));
  const querySnapshot = await getDocs(q);

  let accounts = [];
  querySnapshot.forEach((doc) => {
    accounts.push(doc.data());
  });
  accountStore.setAccounts(accounts);
};

/**
 * Check if the user is an admin and fetch the appropriate accounts.
 */
export const checkAdminAndFetch = async (accountStore) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error("User is not authenticated.");
    return;
  }

  try {
    const idToken = await user.getIdToken();
    const decodedToken = jwtDecode(idToken);

    if (decodedToken.admin) {
      await fetchAllAccounts(accountStore); // Fetch all accounts if admin
      await fetchAllPosts(accountStore);
      accountStore.setIsAdmin(true);
    } else {
      await fetchAccounts(accountStore); // Fetch only user's accounts if not admin
      accountStore.setIsAdmin(false);
    }
  } catch (error) {
    console.error("Error fetching user claims or token:", error);
  }
};