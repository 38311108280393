import { observer } from 'mobx-react';
import React, { useContext, useState, useEffect } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const checkFieldsNotNull = (accountStore) => {
    if (accountStore.selectedAccount === null || accountStore.selectedAccount === undefined || accountStore.selectedAccount === "") {
        return "You must select an account first";
    }

    const fields = [
        { value: accountStore.keyword1, name: "Keyword 1" },
        { value: accountStore.keyword2, name: "Keyword 2" },
        { value: accountStore.keyword3, name: "Keyword 3" },
        { value: accountStore.daily, name: "Daily" },
        { value: accountStore.startDate, name: "Start Date" },
        { value: accountStore.backlink, name: "Backlink" },
        { value: accountStore.hashtags, name: "Hashtags" },
        { value: accountStore.tone, name: "Tone" },
        { value: accountStore.wordCount, name: "WordCount"},
    ];

    for (let field of fields) {
        if (!field.value) {
            return `Please fill in the ${field.name} field before saving.`;
        }
    }

    return null;
}

const ErrorsComponent = observer(() => {
    const accountStore = useContext(AccountStoreContext);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const error = checkFieldsNotNull(accountStore);
        if (error) {
            setErrorMessage(error);
        } else {
            setErrorMessage(null);
        }
    }, [accountStore.keyword1, accountStore.keyword2, accountStore.keyword3, accountStore.daily, accountStore.startDate, accountStore.backlink, accountStore.hashtags, accountStore.tone, accountStore.startTime, accountStore.selectedAccount, accountStore.wordCount, accountStore]);

    return(
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white'}}>
            {errorMessage && 
            <div style={{ fontSize: 10, marginTop: 10, color: 'red', marginBottom: 10 }}>
                {errorMessage}
            </div>
            }
        </div>
    );
})
export default ErrorsComponent;