import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';

const FrequencyDropdown = observer(() => {
  const accountStore = useContext(AccountStoreContext);

  return(
    <form style={{ padding: 10 }}>
      <div style={{ display:'flex', alignItems:'center' }}>
        <input id="daily" type="radio" name="frequency" value="daily" checked={accountStore.daily === 'daily'}
          onChange={() => { 
            accountStore.setDaily('daily'); 
          }}/>
        <label htmlFor="daily" style={{marginLeft: 10, fontFamily: 'nexa_lightregular', fontSize: 10 }}>
          Daily
        </label>
      </div>
      <div style={{ display:'flex', alignItems:'center' }}>
        <input id="weekly" type="radio" name="frequency" value="weekly" checked={accountStore.daily === 'weekly'}
          onChange={() => {
            accountStore.setDaily('weekly'); 
          }}/>
        <label htmlFor="weekly" style={{marginLeft: 10, fontFamily: 'nexa_lightregular', fontSize: 10}}>
          Weekly
        </label>
      </div>
      <div style={{ display:'flex', alignItems:'center' }}>
        <input id="biweekly" type="radio" name="frequency" value="biweekly" checked={accountStore.daily === 'biweekly'}
          onChange={() => {
            accountStore.setDaily('biweekly');
          }}/>
        <label htmlFor="biweekly" style={{ marginLeft: 10, fontFamily: 'nexa_lightregular', fontSize: 10 }}>
          Bi-weekly
        </label>
      </div>
    </form>
  );
})
export default FrequencyDropdown;