import { observer } from 'mobx-react';
import React, { useState, useContext, useEffect } from 'react';
import { AccountStoreContext } from '../store/AccountStore';
import { Timestamp } from 'firebase/firestore';

const TimeDropdown = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const initialHour = accountStore.startHour ? accountStore.startHour.toString().padStart(2, '0') : Timestamp.now().toDate().getHours().toString().padStart(2, '0');
  const [hour, setHour] = useState(initialHour);
  const initialMinute = accountStore.startMinute ? accountStore.startMinute.toString().padStart(2, '0') : '00';
  const [minute, setMinute] = useState(initialMinute);

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i % 15 === 0 ? i.toString().padStart(2, '0') : null).filter(Boolean);

  const handleHourChange = (event) => {
    const newHour = event.target.value;
    setHour(newHour);
    accountStore.setStartHour(parseInt(newHour, 10));
    updateDate(newHour, minute);
  };

  const handleMinuteChange = (event) => {
    const newMinute = event.target.value;
    setMinute(newMinute);
    accountStore.setStartMinute(parseInt(newMinute, 10));
    updateDate(hour, newMinute);
  };

  const updateDate = (newHour, newMinute) => {
    const date = accountStore.startDate.toDate();
    const updatedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      parseInt(newHour, 10),
      parseInt(newMinute, 10)
    );
    const updatedTimestamp = Timestamp.fromDate(updatedDate);
    accountStore.setStartDate(updatedTimestamp);
    console.log('Updated Date', accountStore.startDate.toDate())
  };

  useEffect(() => {
    if(accountStore.startHour) {
      setHour(accountStore.startHour);
    }
    if(accountStore.startMinute) {
      setMinute(accountStore.startMinute);
    }
    console.log(accountStore.startMinute);
    console.log(accountStore.startHour);
  }, [accountStore.startHour, accountStore.startMinute]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0px', gap: 16, boxShadow: 'rgba(0, 0, 0, 0.1) 0px -1px 0px inset' }}>
      <select style={{ padding: '0px 20px', fontSize: '14px', borderRadius: '8px', border: '1px solid #ccc', backgroundColor: '#fff', appearance: 'none', outline: 'none', boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)', cursor: 'pointer', transition: 'all 0.2s ease' }}
        value={hour}
        onChange={handleHourChange}>
        {hours.map((h, index) => (
          <option key={`${h}-${index}`} value={h}>
            {h}
          </option>
        ))}
      </select>
      :
      <select style={{ padding: '0px 20px', fontSize: '14px', borderRadius: '8px', border: '1px solid #ccc', backgroundColor: '#fff', appearance: 'none', outline: 'none', boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)', cursor: 'pointer', transition: 'all 0.2s ease' }}
        value={minute}
        onChange={handleMinuteChange}>
        {minutes.map((m, index) => (
          <option key={`${m}-${index}`} value={m}>
            {m}
          </option>
        ))}
      </select>
    </div>
  );
})
export default TimeDropdown;